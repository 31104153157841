export const environment = {
    production: false,
    title: ' Staging Environment',
    // baseUrl: "https://www.forestumbrella.co.uk/api/v1",
    baseUrl: "https://api.app-demo.store/api/v1",
    // imageUrl: "https://www.forestumbrella.co.uk",
    imageUrl: "https://api.app-demo.store",
    file_url:""
};



