<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container back-link">
    <a routerLink="/candidate-list" class="cursor_pointer h5"><i class="fa fa-angle-double-left me-2"></i>Back</a>
</div>



<div class="single-profile-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="single-profile-item">
                    <!-- <img [src]="candidateInfo?.candidateImageDetails?.imageUrl" alt="Profile"> -->

                    <!-- <div class="single-profile-left">
                        <div class="single-profile-contact">
                            <h3>Contact Info</h3>

                            <ul>
                                <li><i class="icofont-ui-call"></i> <a href="tel:+07554332322">Call : {{candidateInfo?.phoneNumber}}</a></li>
                                <li><i class="icofont-email"></i> <a href="mailto:hello&#64;peof.com">{{candidateInfo?.email}}</a></li>
                                <li><i class="icofont-location-pin"></i>{{candidateInfo?.address}}</li>
                            </ul>
                        </div>

                        <div class="single-profile-skills">
                            <h3>Skills</h3>

                            <div *ngFor="let data of candidateInfo?.skills">
                                <p>{{data}}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            
            <div class="col-lg-7">
                <div class="single-profile-item">
                    <div class="single-profile-right">
                        <div class="single-profile-name">
                            <h2>{{candidateInfo?.publicName}}</h2>
                            <span>{{candidateInfo?.category ? candidateInfo?.category : "***" }}</span>
                            <!-- <a routerLink="/">View CV <i class="icofont-eye-alt"></i></a>
                            <a routerLink="/">Download CV <i class="icofont-download"></i></a> -->
                        </div>

                        <div class="single-profile-textarea">
                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Description</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p class="single-profile-p">{{candidateInfo?.description ? candidateInfo?.description : "*****"}}</p>
                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Education</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <ng-container *ngIf="candidateInfo?.qualifications?.length > 0 ; else noData">
                                <ul>
                                    <li *ngFor="let data of candidateInfo?.qualifications">{{data?.courseOrQualification}}
                                        <span *ngIf="data?.finishedYear">({{data?.finishedYear}})</span>
                                    </li>
                                </ul>
                            </ng-container>
                            <ng-template #noData>
                             <p>No Education</p>
                            </ng-template>
                            </div>



                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Years Of Experiences</h3>
                            </div>
                            
                            <div class="single-profile-paragraph">
                                <ng-container *ngIf="candidateInfo?.yearsOfExperience?.length > 0 ; else noData">
                                <ul>
                                <li *ngFor="let experience of candidateInfo?.yearsOfExperience">
                                    
                                    <div class="d-flex justify-content-between w-100">
                                        <div>
                                    <h6>{{experience.jobTitle}}</h6>
                                    <p>{{experience.companyName}}</p>
                                </div>
                                <div>
                                <ng-container *ngIf="experience?.endedMonth && experience?.endedYear; else presentTemplate" >
                                    <h6>({{experience?.startedYear}}) -  ({{experience?.endedYear}})</h6>
                                  </ng-container>
                                
                                  <ng-template #presentTemplate>
                                    <div>
                                        <h6>({{experience?.startedYear}}) - present </h6>
                                    </div>
                                  </ng-template>                                
                                </div>
                                </div>
                                  </li>
                                </ul>
                            </ng-container>
                            <ng-template #noData>
                             <p>No Experience</p>
                            </ng-template>
                            </div>
                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Skills</h3>
                            </div>
                            <ng-container *ngIf="candidateInfo?.skills?.length > 0 ; else noData">
                            <div *ngFor="let data of candidateInfo?.skills">
                                    <p>{{data}}</p>
                                </div>
                            </ng-container>
                        <ng-template #noData>
                            <p>*****</p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>