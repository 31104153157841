import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CandidateEmployerListService } from 'src/app/shared/services/candidate-employer-list.service';
import { JobCreateService } from 'src/app/shared/services/job-create.service';

@Component({
    selector: 'app-candidate-list',
    templateUrl: './candidate-list.component.html',
    styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {
    candidateList: any[] = [];
    candidateId: number;
    dataNotFound: string = "Candidate's Not Found";
    currentPage: number = 1;
    totalItemsPerPage = 10;
    totalPages: number = 0;
    totalItems: number = 0;
    firstName: string = "";
    middleName: string = "";
    lastName: string = "";
    email: string = "";
    phoneNumber: string = "";
    employerEmail: FormGroup | any;
    qualification: any[] = [];
    selectedCandidateId: any;
    jobCategory: any[] = [];
    category: string = '';
    name: string = '';
    country: string = '';



    constructor(
        private readonly candidateListService: CandidateEmployerListService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.category = params['category']
        });
        this.getAllCandidateList();
    }

    //get all Public candidate list
    getAllCandidateList() {
        this.candidateListService
            .getAllPublicCandidateList(
                this.currentPage,
                this.totalItemsPerPage,
                this.category,
                this.country
            )
            .subscribe((res: any) => {
                this.candidateList = res?.body;
                this.totalItems = res?.totalRecords;
                this.totalPages = Math.ceil(
                    this.totalItems / this.totalItemsPerPage
                );
            });
    }

    assign(id: any) {
        this.candidateId = id;
    }

    openModel(id: string) {
        this.selectedCandidateId = id;
    }

    onPageChange(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.getAllCandidateList();
            window.scrollTo(0, 0);
        }
    }


    
}
