import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { State } from "country-state-city";
import { JobTitleService } from "src/app/shared/dummy-data/job-create.service";
import { ApplyJobService } from "src/app/shared/services/apply-job.service";
import { JobCreateService } from "src/app/shared/services/job-create.service";

@Component({
    selector: "app-job-list",
    templateUrl: "./job-list.component.html",
    styleUrl: "./job-list.component.scss",
})
export class JobListComponent {
    jobInfo: any[] = [];
    jobDetails: any[] = [];
    jobRequirement: any = [];
    jobCategory: any[] = [];
    categoryArray: any = [];
    jobRequirements: [];
    category: any[] = [];
    jobType: any;
    jobTypes: any[] = [];
    jobSalary: any[] = [];
    jobHourlySalary: any[] = [];
    states: any[] = [];
    filteredStates: any[] = [];
    images: any = {};
    currentPage: number = 1;
    totalItems: number = 0;
    totalItemsPerPage = 20;
    totalPages: number = 0;
    imageUrl: any;
    selectedCategory: any;
    jobTitle: string = "";
    jobLocation: string = "";
    jobExperience: string = "";
    salaryRange: string = "";
    dataNotFound: string = "Job Information Not Found";
    searchQuery: string = "";
    public selectedState: string; 
    public searchQueryCity: string = '';
    // public filteredCities: string[] = [];
    constructor(
        private readonly jobCreateService: JobCreateService,
        private readonly jobTitleService: JobTitleService,
        private readonly applyJobService:ApplyJobService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const job = params['job'] || ''; 
            this.category.push(job);
            console.log('Keyword:', params['job']);
        });
        this.jobInfoListing();
        this.getJobCategories();
        this.getJobTypes();
        this.getJobSalary();
        this.getJobHourlySalary();
        this.states = State.getStatesOfCountry("AU");
    }

    //job category drop-down list
    getJobCategories() {
        this.applyJobService.getAllJobCategoriesForFilter()
            .subscribe((jobListing: any) => {
                console.log('bbbb',jobListing)
                this.jobCategory = jobListing.body.map((job: any) => ({
                    category: `${job.categoryName}  (${job.jobCount})`,
                    originalCategory: job.categoryName,
                    totalJobs: job.jobCount,
                }));
            });
    }

    //on search city location of australia
    onSearchCity(query: string): void {
        if (query) {
            this.filteredStates = this.states.filter((state) =>
                state.name.toLowerCase().includes(query.toLowerCase())
            );
        } else {
            this.filteredStates = [];
        }
    }

 

    //Action on selecting a state from the dropdown
    selectState(state: any): void {
        this.searchQuery = state.name;
        this.filteredStates = [];
    }

    //job types drop-down list
    getJobTypes() {
        this.jobTypes = this.jobTitleService.getJobType();
    }

    //job salary
    getJobSalary() {
        this.jobSalary = this.jobTitleService.getJobSalary().map((job) => {
            let salaryRange = job.salary.split(" - ");

            return {
                ...job,
                salary: `${salaryRange[0]}k - ${salaryRange[1]}k`,
            };
        });
    }

    //job hourly salary
    getJobHourlySalary() {
        this.jobHourlySalary = this.jobTitleService
            .getHourlySalary()
            .map((job) => {
                let salaryRange = job.salary.split(" - ");

                return {
                    ...job,
                    salary: `$${salaryRange[0]} - $${salaryRange[1]}`,
                };
            });
    }

    //on select of job category
    onSelectCategory(selectedItems: any) {
        this.category = [];
        selectedItems.value.forEach((item: any) => {
            console.log(item.originalCategory);
            if (!this.category.includes(item.originalCategory)) {
                this.category.push(item.originalCategory);
            }
        });
        this.jobInfoListing();
    }

    //on select of job type
    onSelectJobType(selectedJobType: any) {
        this.jobType = selectedJobType?.value?.type;
        if (this.jobType === "Hourly") {
            this.jobSalary = this.jobHourlySalary;
        } else {
            this.getJobSalary();
        }
        this.jobInfoListing();
    }

    //on select salary Range
    onSelectSalaryRange(event: any) {
        this.salaryRange = event?.value?.salary;
        this.salaryRange = this.salaryRange.replace(/k|\$/g, "").trim();
        this.jobInfoListing();
    }

    //job listing
    jobInfoListing() {
        this.jobCreateService
            .getJobListing(
                this.currentPage,
                this.totalItemsPerPage,
                this.category,
                this.jobTitle,
                this.jobLocation,
                this.jobExperience,
                this.salaryRange,
                this.jobType
            )
            .subscribe((data: any) => {
                this.jobInfo = data?.body;
                if (this.jobInfo) {
                    this.totalItems = data?.totalRecords;
                    this.totalPages = Math.ceil(
                        this.totalItems / this.totalItemsPerPage
                    );
                } else {
                    this.jobRequirement = [];
                }
            });
    }

    onPageChange(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.jobInfoListing();
            window.scrollTo(0, 0); 
        }
    }

    //ngx-selector-dropDown-Configuration for job category
    config = {
        displayKey: "category",
        search: true,
        height: "auto",
        placeholder: "Job Category",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "category",
        clearOnSelection: false,
        inputDirection: "ltr",
        multiple: true,
    };

    //ngx-selector-dropDown-Configuration for job type
    jobTypeConfig = {
        displayKey: "type",
        search: true,
        height: "auto",
        placeholder: "Select job Type",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "type",
        clearOnSelection: false,
        inputDirection: "ltr",
    };
    //ngx-selector-dropDown-Configuration (job salary)
    jobSalaryConfig = {
        displayKey: "salary",
        search: true,
        height: "auto",
        placeholder: "Select Salary Range",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "salary",
        clearOnSelection: false,
        inputDirection: "ltr",
    };
}
